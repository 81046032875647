<template>
  <card-with-switch-component
    :isOpen="model.retry"
    title="Retries"
    hint="Enable retry behavior"
    @change="onRetryEnabledChange"
    data-cy="retryEnabled"
  >
    <v-row v-if="model.retry">
      <v-col cols="6">
        <v-text-field
          id="panelRetriesTimeout"
          data-cy="timeout"
          v-model="model.retry.timeout"
          label="Timeout (seconds)"
          type="number"
          :rules="rules.timeout"
          ref="timeout"
          @input="$refs.interval.validate()"
          hint="The max amount of time the datasource can last, including retries"
          persistent-hint
        ></v-text-field>
      </v-col>
      <v-col cols="6">
        <v-text-field
          id="panelRetriesInterval"
          data-cy="interval"
          v-model="model.retry.interval"
          type="number"
          label="Interval (seconds)"
          :rules="rules.interval"
          ref="interval"
          @input="$refs.timeout.validate()"
          hint="The time to wait between each retry"
          persistent-hint
        ></v-text-field>
      </v-col>
    </v-row>
  </card-with-switch-component>
</template>
<script lang="ts">
import CardWithSwitchComponent from "@/components/card-with-switch.vue";
import { DataSourceConfigurationDto, DataSourceRetryDto } from "@/datahub-api";
import { Validators } from "@/helpers";
import { VuetifyControl } from "@/plugins";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component({
  components: {
    CardWithSwitchComponent,
  },
})
export default class DataSourceRetryComponent extends Vue {
  @Prop() model: DataSourceConfigurationDto;

  private timeSpansInSeconds = {
    oneMinute: 60,
    oneDay: 86400,
    oneWeek: 604800,
  };

  get rules() {
    return {
      timeout: [
        Validators.Numbers.Min(1),
        Validators.Numbers.Max(this.timeSpansInSeconds.oneMinute),
        this.crossValidateTimeout,
      ],
      interval: [
        Validators.Numbers.Min(0),
        Validators.Numbers.Max(this.timeSpansInSeconds.oneMinute),
        this.crossValidateInterval,
      ],
    };
  }

  $refs: {
    timeout: VuetifyControl;
    interval: VuetifyControl;
  };

  crossValidateTimeout(value: string) {
    if (value && !this.model.retry?.interval) {
      return "You need to specify also the interval";
    }

    if (Number(value) < Number(this.model.retry!.interval)) {
      return "Timeout can not be less then interval";
    }

    return true;
  }
  crossValidateInterval(value: string) {
    if (value && !this.model.retry?.timeout) {
      return "You need to specify also the timeout";
    }

    if (Number(value) > Number(this.model.retry?.timeout)) {
      return "Interval can not be greater then timeout";
    }

    return true;
  }

  onRetryEnabledChange(isEnabled: boolean) {
    if (isEnabled) {
      this.model.retry = {
        interval: 1,
        timeout: 10,
      };
      this.model.asyncOptions = null;
    } else {
      this.model.retry = null;
    }
  }

  async refreshRules() {
    await this.$nextTick();
    this.$refs.timeout.validate();
    this.$refs.interval.validate();
  }
}
</script>
