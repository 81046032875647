<template>
  <v-dialog v-model="showDialog" persistent :max-width="1500">
    <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on">
        <slot></slot>
      </span>
    </template>

    <v-card class="mx-auto" outlined>
      <v-app-bar>
        <h2>Data source</h2>
      </v-app-bar>
      <v-progress-circular
        style="margin-left: 50%"
        :size="50"
        color="primary"
        indeterminate
        v-if="!model || !model.configuration"
      ></v-progress-circular>
      <v-card-text v-else>
        <v-form ref="form">
          <v-row>
            <v-col cols="6">
              <v-text-field
                data-cy="name"
                v-model="model.name"
                :disabled="!!datasourceId"
                label="Data source name"
                :rules="rules.name"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                data-cy="description"
                v-model="model.description"
                label="Description of the data source"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-select
                data-cy="http-verb"
                :items="$lookupService.httpVerbs"
                v-model="model.configuration.httpVerb"
                label="Http Verb"
                hint="The http method that will be used in the request"
                persistent-hint
              ></v-select>
            </v-col>
            <v-col v-for="item in model.configuration.stages" :key="item.stage" cols="4">
              <dataSourceStage :dataSourceStage="item" />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field
                id="query-string"
                label="Insert here the query string for both stages (eg. /search?name=$fullName$)"
                data-cy="query-string"
                v-model="model.queryString"
                :hint="queryStringPreview"
                persistent-hint
              ></v-text-field>
            </v-col>
          </v-row>
          <dataSourceDataServiceConfiguration
            v-if="isFrissDataProvider"
            :model="model"
            @apply="applyDataServiceRequest"
          />
          <v-expansion-panels multiple v-model="panelModel" style="margin-top: 15px">
            <v-expansion-panel>
              <v-expansion-panel-header data-cy="request-body-panel" v-slot="{ open }">
                <div style="display: flex; justify-content: space-between">
                  <span>Request body</span>
                  <small>The request payload that will be sent to the vendor</small>
                  <span v-if="!open" class="panel-summary">{{
                    model.requestBody
                      ? (model.configuration.contentType || "") + " payload"
                      : "no request body"
                  }}</span>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content eager>
                <dataSourceRequestBody :model="model" /></v-expansion-panel-content
            ></v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header
                data-cy="request-parameters-panel"
                v-slot="{ open }"
              >
                <div style="display: flex; justify-content: space-between">
                  <span> Request parameters </span>
                  <small
                    >The list of parameters that DataHub expects when it gets
                    invoked</small
                  >
                  <span v-if="!open" class="panel-summary"
                    >{{ model.configuration.params.length }} param(s)</span
                  >
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <dataSourceParams :dataSource="model" /> </v-expansion-panel-content
            ></v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header data-cy="authentication-panel" v-slot="{ open }">
                <div style="display: flex; justify-content: space-between">
                  <span>Authentication</span>
                  <span v-if="!open" class="panel-summary"
                    >Using
                    {{
                      $lookupService.authenticationMethods
                        .find((a) => a.value == model.configuration.authenticationMethod)
                        .text.toLowerCase()
                    }}
                  </span>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content eager>
                <dataSourceConfiguration
                  v-if="showDialog && model.configuration"
                  ref="datasourceConfiguration"
                  :model="model.configuration"
                  :dataSource="model"
                  :isFrissDataService="isFrissDataProvider"
                  @onConfigurationChanged="updateModel" /></v-expansion-panel-content
            ></v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header data-cy="response-panel" v-slot="{ open }">
                <div style="display: flex; justify-content: space-between">
                  <span>Response format</span>
                  <span v-if="!open" class="panel-summary"
                    >Returning '{{
                      ResponseType[model.configuration.responseType].toLocaleLowerCase()
                    }}'
                  </span>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content eager>
                <v-row>
                  <v-col cols="6">
                    <v-select
                      data-cy="response-type"
                      :items="$lookupService.responseType"
                      v-model="model.configuration.responseType"
                      label="Response Type"
                      :hint="responseTypeHint"
                      persistent-hint
                    >
                    </v-select>
                  </v-col> </v-row></v-expansion-panel-content
            ></v-expansion-panel>
            <v-expansion-panel>
              <v-expansion-panel-header data-cy="advanced-settings-panel">
                <div style="display: flex; justify-content: space-between">
                  <span>Advanced settings</span>
                </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content eager>
                <v-row>
                  <v-col md="12">
                    <dataSourceRetry :model="model.configuration" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col md="12">
                    <dataSourceAsync :model="model.configuration" />
                  </v-col>
                </v-row>
                <v-row>
                  <v-col
                    v-for="dataSourceStage in model.configuration.stages"
                    :key="dataSourceStage.stage"
                    cols="6"
                  >
                    <data-source-stage-proxy
                      :proxy="dataSourceStage.proxy"
                      :stage="dataSourceStage.name"
                    />
                  </v-col>
                </v-row> </v-expansion-panel-content
            ></v-expansion-panel>
          </v-expansion-panels>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn data-cy="cancel" color="secondary" outlined rounded @click="cancel"
          >Cancel</v-btn
        >
        <v-btn
          data-cy="save"
          color="primary"
          rounded
          v-if="$permissions.canModifyDataSource && isDraft"
          @click="save"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import dataSourceRequestBody from "@/views/datasource/datasource.request-body.vue";
import dataSourceConfiguration from "@/views/datasource/datasource.configuration.vue";
import dataSourceDataServiceConfiguration from "@/views/datasource/datasource.dataservice.vue";
import dataSourceStage from "@/views/datasource/datasource.stage.vue";
import dataSourceParams from "@/views/datasource/datasource.params.vue";
import dataSourceStageProxy from "@/views/datasource/datasource.proxy.vue";
import dataSourceRetry from "@/views/datasource/datasource.retry.vue";
import dataSourceAsync from "@/views/datasource/datasource.async.vue";
import { Validators } from "@/helpers";
import eventHub from "@/eventhub";
import constants from "@/services/constants";
import { API, HttpVerb, ResponseType, Stage } from "@/datahub-api";

export default {
  name: "datasource",
  components: {
    dataSourceConfiguration,
    dataSourceRequestBody,
    dataSourceDataServiceConfiguration,
    dataSourceStage,
    dataSourceRetry,
    dataSourceAsync,
    dataSourceStageProxy,
    dataSourceParams,
  },
  props: ["datasourceId", "dataproviderId", "isDraft"],
  data: () => ({
    showDialog: true,
    isFrissDataProvider: false,
    model: {
      configuration: null,
      responseSchema: {},
    },
    rules: {
      name: Validators.Required.Text,
    },
    panelModel: [0, 1, 2, 3],
    Stage: Stage,
    HttpVerb: HttpVerb,
    ResponseType: ResponseType,
  }),
  computed: {
    queryStringPreview() {
      const prdStage = this.model?.configuration?.stages.filter(
        (stage) => stage.name == Stage[Stage.PRD]
      )[0];

      if (!prdStage || !prdStage.dataSourceUri || !this.model?.queryString) {
        return "Preview the production query string here (insert production base uri first)";
      }

      return (
        Stage[Stage.PRD] +
        " absolute url preview: " +
        prdStage.dataSourceUri +
        this.model.queryString
      );
    },
    responseTypeHint() {
      switch (this.model.configuration.responseType) {
        case ResponseType.Json:
        case ResponseType.Xml:
          return "Indicates how to parse the body returned by the vendor (used in expressions)";
        case ResponseType.Base64:
          return "Useful when the vendor returns binary data (eg a zip file). The content will be encoded as a base64 string into DataHub's json response envelope";
        case ResponseType.Raw:
          return "DataHub will return the exact same response as the vendor, without any extra json envelope, effectively acting as a transparent proxy";
      }
      return null;
    },
  },
  mounted: async function () {
    var response = this.datasourceId
      ? await API.dataSourceService.get(this.datasourceId)
      : await API.dataSourceService.add();
    if (response.isSuccess) {
      this.model = response.result;
    }
    if (this.dataproviderId) {
      var providerResponse = await API.dataProviderService.get(this.dataproviderId);
      if (providerResponse.isSuccess) {
        var provider = providerResponse.result;
        this.isFrissDataProvider = provider.name === constants.friss;
      }
    }
    this.$refs?.form?.resetValidation();
  },
  methods: {
    save: async function () {
      if (!this.$refs.form.validate()) {
        this.expandParamPanel();

        eventHub.$emit("notification", constants.clientValidation.defaultMessage);

        return;
      }

      this.model.dataProvider.id = this.dataproviderId;

      const data = JSON.parse(JSON.stringify(this.model));

      let response =
        data.id == constants.guid.empty
          ? await API.dataSourceService.createAsync(data)
          : await API.dataSourceService.updateAsync(data);

      if (response.isSuccess) {
        this.showDialog = false;
        this.$emit("saved");
        setTimeout(() => {
          this.$emit("close");
        }, 100);
      }
    },
    applyDataServiceRequest(request) {
      this.model.configuration.contentType = request.contentType;
      this.model.configuration.responseType = request.responseType;
      this.model.configuration.httpVerb = request.httpVerb;
      this.model.requestBody = request.requestBody;
      this.model = {
        ...this.model,
      };
    },
    cancel() {
      this.showDialog = false;
      setTimeout(() => {
        this.$emit("close");
      }, 100);
    },
    expandParamPanel() {
      const validationErrorsInsideParamsPanel = this.$refs.form.inputs.some(
        (cmp) => cmp.hasError && cmp.$refs.input.id?.includes("paramname")
      );

      if (validationErrorsInsideParamsPanel) {
        const dataSourceParamsCmp =
          this.$refs.datasourceConfiguration.$refs.dataSourceParams;

        dataSourceParamsCmp.expandPanel();
      }
    },
    updateModel(dataSourceDomain) {
      this.model = {
        ...dataSourceDomain,
      };
    },
  },
};
</script>
